.author-photo {
  margin-bottom: 20px;
}
.author-photo img {
  max-width: 100%;
  background-color: #ea6827;
  border: 3px solid #ea6827;
  border-radius: 300px;
}

.author-degree {
  color: #ea6827;
  text-align: center;
}

.experience-job {
  font-size: small;
  position: relative;
}

.experience-lapse {
  text-align: right;
}

.experience-lapse h3 {
  font-size: 12px;
}

.experience-job p {
  color: #aaa;
}

.tech-know li {
  text-decoration: none;
  list-style: none;
  color: white;
  background-color: #ea6827;
  display: inline-block;
  margin: 3px;
  padding: 5px 14px;
}
