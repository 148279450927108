/** Reset some basic elements */
body, h1, h2, h3, h4, h5, h6, p, blockquote, pre, hr, dl, dd, ol, ul, figure { margin: 0; padding: 0; }

/** Basic styling */
body { font: 400 16px/1.5 -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; color: #111; background-color: #fdfdfd; -webkit-text-size-adjust: 100%; -webkit-font-feature-settings: "kern" 1; -moz-font-feature-settings: "kern" 1; -o-font-feature-settings: "kern" 1; font-feature-settings: "kern" 1; font-kerning: normal; display: flex; min-height: 100vh; flex-direction: column; }

/** Set `margin-bottom` to maintain vertical rhythm */
h1, h2, h3, h4, h5, h6, p, blockquote, pre, ul, ol, dl, figure, .highlight { margin-bottom: 15px; }

/** `main` element */
main { display: block; /* Default value of `display` of `main` element is 'inline' in IE 11. */ }

/** Images */
img { max-width: 100%; vertical-align: middle; }

/** Figures */
figure > img { display: block; }

figcaption { font-size: 14px; }

/** Lists */
ul, ol { margin-left: 30px; }

li > ul, li > ol { margin-bottom: 0; }

/** Headings */
h1, h2, h3, h4, h5, h6 { font-weight: 600; }

/** Links */
a { color: #ea6827; text-decoration: none; }

a:visited { color: #ea6827; }

a:hover { color: #D1B13B; text-decoration: underline; }

.logo-ultranaco {width: 145px;}

.social-media-list a:hover { text-decoration: none; }

.social-media-list a:hover .username { text-decoration: underline; }

/** Blockquotes */
blockquote { color: #828282; border-left: 4px solid #e8e8e8; padding-left: 15px; font-size: 18px; letter-spacing: -1px; font-style: italic; }

blockquote > :last-child { margin-bottom: 0; }

/** Code formatting */
pre, code { font-size: 15px; border: 1px solid #e8e8e8; border-radius: 3px; background-color: #eef; }

code { padding: 1px 5px; }

pre { padding: 8px 12px; overflow-x: auto; }

pre > code { border: 0; padding-right: 0; padding-left: 0; }

/** Wrapper */
.wrapper { max-width: -webkit-calc(800px - (30px * 2)); max-width: calc(800px - (30px * 2)); margin-right: auto; margin-left: auto; padding-right: 30px; padding-left: 30px; }

@media screen and (max-width: 800px) { .wrapper { max-width: -webkit-calc(800px - (30px)); max-width: calc(800px - (30px)); padding-right: 15px; padding-left: 15px; } }

/** Clearfix */
.footer-col-wrapper:after, .wrapper:after { content: ""; display: table; clear: both; }

/** Icons */
.svg-icon { width: 21px; height: 21px; display: inline-block; fill: #828282; padding-right: 5px; vertical-align: text-top; }

.social-media-list li + li { padding-top: 5px; }

/** Tables */
table { margin-bottom: 30px; width: 100%; text-align: left; color: #3f3f3f; border-collapse: collapse; border: 1px solid #e8e8e8; }

table tr:nth-child(even) { background-color: #f7f7f7; }

table th, table td { padding: 10px 15px; }

table th { background-color: #f0f0f0; border: 1px solid #dedede; border-bottom-color: #c9c9c9; }

table td { border: 1px solid #e8e8e8; }

/** Site header */
.site-header { border-top: 5px solid #424242; border-bottom: 1px solid #e8e8e8; min-height: 55.95px; position: relative; }

.site-title { font-size: 26px; font-weight: 300; line-height: 54px; letter-spacing: -1px; margin-bottom: 0; float: left; }

.site-title, .site-title:visited { color: #424242; }

.site-nav { float: right; line-height: 54px; }

.site-nav .nav-trigger { display: none; }

.site-nav .menu-icon { display: none; }

.site-nav .page-link { color: #111; line-height: 1.5; }

.site-nav .page-link-current {font-weight: bold;}

.site-nav .page-link:not(:last-child) { margin-right: 20px; }

.site-nav .trigger { display: inline-block; margin: 0 5px; }

@media screen and (max-width: 600px) { .site-nav { position: absolute; top: 9px; right: 15px; background-color: #fdfdfd; border: 1px solid #e8e8e8; border-radius: 5px; text-align: right; } .site-nav label[for="nav-trigger"] { display: block; float: right; width: 36px; height: 36px; z-index: 2; cursor: pointer; } .site-nav .menu-icon { display: block; float: right; width: 36px; height: 26px; line-height: 0; padding-top: 10px; text-align: center; } .site-nav .menu-icon > svg { fill: #424242; } .site-nav input ~ .trigger { clear: both; display: none; } .site-nav input:checked ~ .trigger { display: block; padding-bottom: 5px; } .site-nav .page-link { display: block; padding: 5px 10px; margin-left: 20px; } .site-nav .page-link:not(:last-child) { margin-right: 0; } }

/** Site footer */
.site-footer { border-top: 1px solid #e8e8e8; padding: 30px 0; }

.footer-heading { font-size: 18px; margin-bottom: 15px; }

.contact-list, .social-media-list { list-style: none; margin-left: 0; }

.footer-col-wrapper { font-size: 15px; color: #828282; margin-left: -15px; }

.footer-col { float: left; margin-bottom: 15px; padding-left: 15px; }

.footer-col-1 { width: -webkit-calc(35% - (30px / 2)); width: calc(35% - (30px / 2)); }

.footer-col-2 { width: -webkit-calc(20% - (30px / 2)); width: calc(20% - (30px / 2)); }

.footer-col-3 { width: -webkit-calc(45% - (30px / 2)); width: calc(45% - (30px / 2)); }

@media screen and (max-width: 800px) { .footer-col-1, .footer-col-2 { width: -webkit-calc(50% - (30px / 2)); width: calc(50% - (30px / 2)); } .footer-col-3 { width: -webkit-calc(100% - (30px / 2)); width: calc(100% - (30px / 2)); } }

@media screen and (max-width: 600px) { .footer-col { float: none; width: -webkit-calc(100% - (30px / 2)); width: calc(100% - (30px / 2)); } }

/** Page content */
.page-content { padding: 30px 0; flex: 1; }

.page-heading { font-size: 32px; }

.post-list-heading { font-size: 28px; }

.post-list { margin-left: 0; list-style: none; }

.post-list > li { margin-bottom: 30px; }

.post-meta { font-size: 14px; color: #828282; }

.post-link { display: block; font-size: 24px; }

/** Posts */
.post-header { margin-bottom: 30px; }

.post-title { font-size: 42px; letter-spacing: -1px; line-height: 1; }

@media screen and (max-width: 800px) { .post-title { font-size: 36px; } }

.post-content { margin-bottom: 30px; }

.post-content h2 { font-size: 32px; }

@media screen and (max-width: 800px) { .post-content h2 { font-size: 28px; } }

.post-content h3 { font-size: 26px; }

@media screen and (max-width: 800px) { .post-content h3 { font-size: 22px; } }

.post-content h4 { font-size: 20px; }

@media screen and (max-width: 800px) { .post-content h4 { font-size: 18px; } }

/** Syntax highlighting styles */
.highlight { background: #fff; }

.highlighter-rouge .highlight { background: #eef; }

.highlight .c { color: #998; font-style: italic; }

.highlight .err { color: #a61717; background-color: #e3d2d2; }

.highlight .k { font-weight: bold; }

.highlight .o { font-weight: bold; }

.highlight .cm { color: #998; font-style: italic; }

.highlight .cp { color: #999; font-weight: bold; }

.highlight .c1 { color: #998; font-style: italic; }

.highlight .cs { color: #999; font-weight: bold; font-style: italic; }

.highlight .gd { color: #000; background-color: #fdd; }

.highlight .gd .x { color: #000; background-color: #faa; }

.highlight .ge { font-style: italic; }

.highlight .gr { color: #a00; }

.highlight .gh { color: #999; }

.highlight .gi { color: #000; background-color: #dfd; }

.highlight .gi .x { color: #000; background-color: #afa; }

.highlight .go { color: #888; }

.highlight .gp { color: #555; }

.highlight .gs { font-weight: bold; }

.highlight .gu { color: #aaa; }

.highlight .gt { color: #a00; }

.highlight .kc { font-weight: bold; }

.highlight .kd { font-weight: bold; }

.highlight .kp { font-weight: bold; }

.highlight .kr { font-weight: bold; }

.highlight .kt { color: #458; font-weight: bold; }

.highlight .m { color: #099; }

.highlight .s { color: #d14; }

.highlight .na { color: #008080; }

.highlight .nb { color: #0086B3; }

.highlight .nc { color: #458; font-weight: bold; }

.highlight .no { color: #008080; }

.highlight .ni { color: #800080; }

.highlight .ne { color: #900; font-weight: bold; }

.highlight .nf { color: #900; font-weight: bold; }

.highlight .nn { color: #555; }

.highlight .nt { color: #000080; }

.highlight .nv { color: #008080; }

.highlight .ow { font-weight: bold; }

.highlight .w { color: #bbb; }

.highlight .mf { color: #099; }

.highlight .mh { color: #099; }

.highlight .mi { color: #099; }

.highlight .mo { color: #099; }

.highlight .sb { color: #d14; }

.highlight .sc { color: #d14; }

.highlight .sd { color: #d14; }

.highlight .s2 { color: #d14; }

.highlight .se { color: #d14; }

.highlight .sh { color: #d14; }

.highlight .si { color: #d14; }

.highlight .sx { color: #d14; }

.highlight .sr { color: #009926; }

.highlight .s1 { color: #d14; }

.highlight .ss { color: #990073; }

.highlight .bp { color: #999; }

.highlight .vc { color: #008080; }

.highlight .vg { color: #008080; }

.highlight .vi { color: #008080; }

.highlight .il { color: #099; }

/*# sourceMappingURL=main.css.map */
* {
  box-sizing: border-box;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}

[class*="xcol-"] {
  float: left;
  padding: 10px;
  position: relative;
}

/* For mobile phones: */
[class*="xcol-"] {
  width: 100%;
}

@media only screen and (min-width: 160px) {
  /* For mobile: */
  .xcol-xs-1 {width: 8.33%;}
  .xcol-xs-2 {width: 16.66%;}
  .xcol-xs-3 {width: 25%;}
  .xcol-xs-4 {width: 33.33%;}
  .xcol-xs-5 {width: 41.66%;}
  .xcol-xs-6 {width: 50%;}
  .xcol-xs-7 {width: 58.33%;}
  .xcol-xs-8 {width: 66.66%;}
  .xcol-xs-9 {width: 75%;}
  .xcol-xs-10 {width: 83.33%;}
  .xcol-xs-11 {width: 91.66%;}
  .xcol-xs-12 {width: 100%;}
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .xcol-s-1 {width: 8.33%;}
  .xcol-s-2 {width: 16.66%;}
  .xcol-s-3 {width: 25%;}
  .xcol-s-4 {width: 33.33%;}
  .xcol-s-5 {width: 41.66%;}
  .xcol-s-6 {width: 50%;}
  .xcol-s-7 {width: 58.33%;}
  .xcol-s-8 {width: 66.66%;}
  .xcol-s-9 {width: 75%;}
  .xcol-s-10 {width: 83.33%;}
  .xcol-s-11 {width: 91.66%;}
  .xcol-s-12 {width: 100%;}
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .xcol-1 {width: 8.33%;}
  .xcol-2 {width: 16.66%;}
  .xcol-3 {width: 25%;}
  .xcol-4 {width: 33.33%;}
  .xcol-5 {width: 41.66%;}
  .xcol-6 {width: 50%;}
  .xcol-7 {width: 58.33%;}
  .xcol-8 {width: 66.66%;}
  .xcol-9 {width: 75%;}
  .xcol-10 {width: 83.33%;}
  .xcol-11 {width: 91.66%;}
  .xcol-12 {width: 100%;}
}

.site-nav .trigger {
  font-weight: 600;
}

.new-section, .new-section-rigth {
  position: relative;
}

.new-section::before {
  content: "";
  float: left;
  width: 70%;
  margin: 20px 0;
  border-top: 3px solid #ddd;
}

.new-section-rigth::before {
  content: "";
  float: right;
  width: 70%;
  margin: 20px 0;
  border-top: 3px solid #ddd;
}

.new-section::after, .new-section-rigth::after {
  content: "";
  clear: both;
  display: table;
}

.page-title,.page-title h1{
  display: inline-block;
  position: relative;
}

.page-title::after {
  position: absolute;
  opacity: 0.45;
  top: 35%;
  right: -15px;
  content: '';
  height: 30px;
  width: 50px;
  background-image: -webkit-repeating-radial-gradient(center center, #ea6827, #ea6827 1px, transparent 0px, transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center, #ea6827, #ea6827 1px, transparent 0px, transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center, #ea6827, #ea6827 1px, transparent 0px, transparent 100%);
  -webkit-background-size: 6px 6px;
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  z-index: 0;
}

.dot-shadow:after {
  position: absolute;
  opacity: 0.3;
  top: 7%;
  left: 7%;
  border-radius: 300px;
  content: '';
  height: calc(100% + 0px);
  width: calc(100% + 0px);
  background-image: -webkit-repeating-radial-gradient(center center, #D1B13B, #D1B13B 1px, transparent 0px, transparent 100%);
  background-image: -moz-repeating-radial-gradient(center center, #D1B13B, #D1B13B 1px, transparent 0px, transparent 100%);
  background-image: -ms-repeating-radial-gradient(center center, #D1B13B, #D1B13B 1px, transparent 0px, transparent 100%);
  -webkit-background-size: 6px 6px;
  -moz-background-size: 6px 6px;
  background-size: 6px 6px;
  z-index: -1;
}

.title-highligth {
  color: #ea6827;
}

.skill-label{
  font-size: small;
}

.skill-name {
  float: left;
  font-weight: 700;
}

.skill-value {
  float: right;
  color: #aaa;
}

.skill-label::after {
  content: "";
  clear: both;
  display: table;
}

.skill-bar {
  margin: 3px 0 10px 0;
  position: relative;
  background-color: #fff;
  border: 1px solid #ea6827;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 10px;
  width: 100%;
}

.skill-percentage {
  position: relative;
  background-color: #ea6827;
  border: 2px solid #fff;
  -moz-border-radius: 9px;
  -webkit-border-radius: 9px;
  border-radius: 9px;
  height: 8px;
  padding: 0;
}

.timeline-divider {
  position: absolute;
  top: 0;
  left: 37.44%;
  bottom: 0;
  width: 1px;
  background-color: #eee;
}

.timeline-divider::before {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: -10px;
  margin-top: 4px;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 10px;
  background-color: #ea6827;
  opacity: .25;
  z-index: 0;
}

.timeline-divider::after {
  content: '';
  display: block;
  position: absolute;
  top: 14px;
  left: -5px;
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #ea6827;
}

@import 'author';
